import React from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import usePrevious from 'react-use/lib/usePrevious';
import { useSpring, animated } from 'react-spring';
import styles from '@styles/components/Collapsible.module.scss';
import type { Styles } from '@material-ui/core/styles/withStyles';

type Props = {
  expand?: boolean,
  children?: React.ReactChildren,
  style?: Styles,
};

export default function Collapsible({
  expand: isOpen,
  children,
  style,
}: Props) {
  const previous = usePrevious(isOpen);
  const [ref, { height: viewHeight }] = useMeasure();
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0 },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
    },
  });

  return (
    <animated.div
      className={styles.content}
      style={{
        opacity,
        height: isOpen && previous === isOpen ? 'auto' : height,
        ...style,
      }}>
      <animated.div style={{ transform }} ref={ref}>
        {children}
      </animated.div>
    </animated.div>
  );
}
