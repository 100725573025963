import { useFormContext } from 'react-hook-form';
import React from 'react';
import FromToSelectorV1 from '@components/FromToSelectorV1';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
export default function WidthButton() {
  const { setValue, watch } = useFormContext();
  const { widthKey, widthFrom, widthTo } = watch();
  const { townhouse } = configs;
  const { width } = townhouse || {};
  const convertWidth =
    width?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 101 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <div className="space-y-2">
      <FromToSelectorV1
        renderToValue={100}
        progress={50.5}
        sliderTitle={'Chiều ngang (m)'}
        key={Math.random() + widthFrom + widthTo}
        deleteValue={() => {
          setValue('widthKey', '');
          setValue('widthFrom', 0);
          setValue('widthTo', 101);
          setValue('areaKey', '');
          setValue('areaFrom', 0);
          setValue('areaTo', 20001);
        }}
        exceptValue={
          (widthFrom === 0 || !widthFrom) && widthTo === 3
            ? '< 3 m'
            : widthFrom === 100 && widthTo === 101
            ? '> 100 m'
            : ''
        }
        theValueIsShow={100}
        listSelections={convertWidth || []}
        onSelectItem={({ from, to, key }) => {
          setValue('widthKey', key);
          setValue('widthFrom', from);
          setValue('widthTo', to);
          setValue('areaKey', '');
          setValue('areaFrom', 0);
          setValue('areaTo', 20001);
        }}
        keyValue={widthKey}
        from={widthFrom}
        to={widthTo}
        unit={'m'}
        min={0}
        max={100}
        step={1}
        additionalItems={[{ key: '', from: 0, to: 101, value: 'Tất cả' }]}
      />
    </div>
  );
}
