import MyDropdownCustom from '@components/MyDropdownCustom';
import SelectComponent from '@components/SelectComponent';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { hasAlleyConfig } from '@components/AdvanceSearch/configs';

export default function HasAlleyButton() {
  const { control } = useFormContext();
  const [disabledWhenDelete, setDisabledWhenDelete] = useState(false);
  return (
    <Controller
      control={control}
      name="hasAlley"
      render={({ field: { onChange, value } }) => {
        return (
          <MyDropdownCustom
            key={Math.random() + value}
            onDelete={() => {
              onChange('');
              setDisabledWhenDelete(true);
              setTimeout(() => {
                setDisabledWhenDelete(false);
              }, 100);
            }}
            value={value}
            setValue={(nextValue) => onChange(nextValue)}
            disabled={disabledWhenDelete}
            hideDeleteButton={value === ''}
            configs={hasAlleyConfig}
            DropdownComponent={SelectComponent}
            contextProps={{ hasItemRadio: true }}
            customValue={() => {
              return (
                <div
                  className={`flex-1 px-2 text-left text-13 truncate text-blue-main`}>
                  {hasAlleyConfig?.find((e) => e.key === value)?.value}
                </div>
              );
            }}
          />
        );
      }}
    />
  );
}
