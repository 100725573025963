import Dropdown from '@components/Dropdown';
import MyInput from '@components/Input';
import { useFormContext, Controller } from 'react-hook-form';
import dynamic from 'next/dynamic';
const Suggestion = dynamic(() => import('./Suggestion'));
import styles from '@styles/components/AdvanceSearch/AdvanceDropdown.module.scss';
export default function Input() {
  const { control } = useFormContext();

  return (
    <div className="flex-1">
      <Dropdown style="flex-1" DropdownComponent={Suggestion}>
        <Controller
          control={control}
          name="searchString"
          render={({ field, field: { onChange } }) => {
            return (
              <MyInput
                {...field}
                showShadow={false}
                onDelete={() => onChange('')}
                inputOuterClassName={styles.input}
                autocomplete="off"
                name="searchString"
                placeholder="Nhập địa điểm hoặc từ khóa (ví dụ: Vinhomes hoặc 137 Lý Thường Kiệt hoặc số điện thoại người đăng tin)"
              />
            );
          }}
        />
      </Dropdown>
    </div>
  );
}
