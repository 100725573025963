import { useFormContext } from 'react-hook-form';
import React from 'react';
import FromToSelectorV1 from '@components/FromToSelectorV1';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';

type Props = {
  floorKey?: string,
  floorFrom?: string,
  floorTo?: string,
};

export default function FloorButton(props: Props) {
  const {
    floorTo = 'floorCountTo',
    floorFrom = 'floorCountFrom',
    floorKey = 'floorCountKey',
  } = props;
  const { setValue, watch } = useFormContext();
  const { realEstateType } = watch();

  const floorCountKey = watch(floorKey),
    floorCountFrom = watch(floorFrom),
    floorCountTo = watch(floorTo);

  const { townhouse } = configs;
  const { floorCount } = townhouse || {};
  const convertFloorCount =
    floorCount?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 169 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <div className="space-y-2">
      <FromToSelectorV1
        renderToValue={168}
        progress={84.5}
        sliderTitle={'Số lượng tầng'}
        key={Math.random() + floorCountFrom + floorCountTo}
        deleteValue={() => {
          setValue(floorKey, '');
          setValue(floorFrom, 0);
          setValue(floorTo, 169);
        }}
        exceptValue={
          floorCountFrom === 0 && floorCountTo === 3 ? '<= 3 tầng' : ''
        }
        disabled={
          realEstateType?.length === 1 &&
          (realEstateType?.[0] === 'building' || realEstateType?.[0] === 'land')
        }
        listSelections={convertFloorCount || []}
        onSelectItem={({ from, to, key }) => {
          setValue(floorKey, key);
          setValue(floorFrom, from);
          setValue(floorTo, to);
        }}
        keyValue={floorCountKey}
        from={floorCountFrom}
        to={floorCountTo}
        unit={'tầng'}
        min={0}
        max={168}
        step={1}
        additionalItems={[
          { key: '', from: 0, to: 169, value: 'Tất cả số tầng' },
        ]}
      />
    </div>
  );
}
