import { useFormContext } from 'react-hook-form';
import React from 'react';
import FromToSelectorV1 from '@components/FromToSelectorV1';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
export default function AreaButton() {
  const { setValue, watch } = useFormContext();
  const { areaKey, areaFrom, areaTo } = watch();
  const { townhouse } = configs;
  const { area } = townhouse || {};
  const convertArea =
    area?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 20001 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <div className="space-y-2">
      <FromToSelectorV1
        renderToValue={20000}
        isLong
        progress={2500.5}
        sliderTitle={'Diện tích (m²)'}
        stepRenderLine={800}
        key={Math.random() + areaFrom + areaTo}
        deleteValue={() => {
          setValue('areaKey', '');
          setValue('areaFrom', 0);
          setValue('areaTo', 20001);
          setValue('widthKey', '');
          setValue('widthFrom', 0);
          setValue('widthTo', 101);
          setValue('lengthKey', '');
          setValue('lengthFrom', 0);
          setValue('lengthTo', 501);
        }}
        exceptValue={
          (areaFrom === 0 || !areaFrom) && areaTo === 10 ? '< 10 m' : ''
        }
        theValueIsShow={5000}
        listSelections={convertArea || []}
        onSelectItem={({ from, to, key }) => {
          setValue('areaKey', key);
          setValue('areaFrom', from);
          setValue('areaTo', to);
          setValue('widthKey', '');
          setValue('widthFrom', 0);
          setValue('widthTo', 101);
          setValue('lengthKey', '');
          setValue('lengthFrom', 0);
          setValue('lengthTo', 501);
        }}
        keyValue={areaKey}
        from={areaFrom}
        to={areaTo}
        unit={'m²'}
        min={0}
        max={5000}
        step={1}
        additionalItems={[{ key: '', from: 0, to: 20001, value: 'Tất cả' }]}
      />
    </div>
  );
}
