import styles from '@styles/components/AdvanceSearch/AdvanceDropdown.module.scss';
import Input from '@components/AdvanceSearch/components/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { useRouter } from 'next/router';
import { pickBy } from 'lodash';
import { allPostType } from '@utils/configs/SearchList/SearchListConfigs';
import {
  convertTypeHomeToProject,
  options,
} from '@components/AdvanceSearch/configs';
import SelectComponent from '@components/SelectComponent';
import React, { useState } from 'react';
import Dropdown from '@components/Dropdown';
import ArrowDown from '@public/svg/arrowV2.svg';
import { routeSearchList } from '@utils/configs/SearchList/const';

export default function AdvanceDropdown() {
  const { control, setValue } = useFormContext();
  const [toggle, onToggle] = useState(false);
  const router = useRouter();
  return (
    <div className={styles.advanceDropdownContainer}>
      {router.pathname !== '/' && (
        <div className="w-135px h-full">
          <Controller
            control={control}
            name="isForSell"
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  onToggle={() => onToggle(!toggle)}
                  DropdownComponent={SelectComponent}
                  dropdownContext={{
                    value,
                    setValue: (nextValue) => {
                      onChange(nextValue);
                      if (nextValue === 'ForRent' || nextValue === 'ToRent') {
                        setValue('priceFrom', 0);
                        setValue('priceTo', 200.5);
                        setValue('priceKey', '');
                        setValue('realEstateType', ['townhouse']);
                      } else if (nextValue === 'Project') {
                        setValue('realEstateType', [
                          ...convertTypeHomeToProject,
                        ]);
                        setValue('priceFrom', 0);
                        setValue('priceTo', 101);
                        setValue('priceKey', '');
                      } else {
                        setValue('priceFrom', 0);
                        setValue('priceTo', 101);
                        setValue('priceKey', '');
                        setValue('realEstateType', ['townhouse']);
                      }
                    },
                    configs: options,
                    hasItemRadio: true,
                  }}
                  style="flex flex-1 justify-center items-center h-full border-r border-blue-main px-2">
                  <div className="w-full flex flex-row items-center justify-between">
                    <div
                      className={`flex-1 px-2 text-left text-15 font-bold truncate text-black`}>
                      {options?.find((e) => e.key === value)?.value}
                    </div>
                    <div className="flex flex-row items-center space-x-2 mr-2">
                      <ArrowDown
                        fill="#888"
                        className={`${
                          toggle
                            ? 'transform rotate-180 duration-300'
                            : 'duration-300'
                        }`}
                      />
                    </div>
                  </div>
                </Dropdown>
              );
            }}
          />
        </div>
      )}
      <Input />
      <div className="flex h-full p-2">
        <SearchButton />
      </div>
    </div>
  );
}

export const getRealEstateType = ({ isForSell, realEstateType }) => {
  if (
    ((isForSell === 'ForSale' || isForSell === 'ToBuy') &&
      realEstateType.length === 10) ||
    ((isForSell === 'ForRent' || isForSell === 'ToRent') &&
      realEstateType.length === 11)
  ) {
    return '';
  } else if (isForSell === 'Project' && realEstateType.length === 5) {
    return '';
  } else {
    return realEstateType;
  }
};
export const getRealEstateType2 = ({ isForSell, realEstateType }) => {
  if (
    ((isForSell === 'ForSale' || isForSell === 'ToBuy') &&
      realEstateType.length === 10) ||
    ((isForSell === 'ForRent' || isForSell === 'ToRent') &&
      realEstateType.length === 11)
  ) {
    return 'allRealEstateType';
  } else if (isForSell === 'Project' && realEstateType.length === 5) {
    return 'allProjectType';
  } else {
    return realEstateType?.[0] || 'townhouse';
  }
};

function SearchButton() {
  const { watch } = useFormContext();
  const router = useRouter();
  const isForSell = watch('isForSell'),
    realEstateType = watch('realEstateType'),
    searchString = watch('searchString'),
    province = watch('province'),
    district = watch('district'),
    areaFrom = watch('areaFrom'),
    areaTo = watch('areaTo'),
    priceFrom = watch('priceFrom'),
    priceTo = watch('priceTo');
  const variables = pickBy(
    {
      isForSell: isForSell === 'Project' ? 'project' : isForSell,
      realEstateTypes: getRealEstateType({ isForSell, realEstateType }),
      searchString,
      province,
      district,
      areaTo: areaTo === 20001 ? null : areaTo,
      areaFrom: areaFrom === 0 ? null : areaFrom,
      priceFrom:
        priceFrom === 0
          ? null
          : isForSell === 'ForRent' || isForSell === 'ToRent'
          ? priceFrom * Math.pow(10, 6)
          : priceFrom * Math.pow(10, 9),
      priceTo:
        priceTo === 101 || priceTo === 200.5
          ? null
          : isForSell === 'ForRent' || isForSell === 'ToRent'
          ? priceTo * Math.pow(10, 6)
          : priceTo * Math.pow(10, 9),
    },
    (e) => e,
  );
  const pathname = `/${routeSearchList}/${isForSell
    ?.trim()
    ?.replace('Project', 'project')}/${getRealEstateType2({
    isForSell,
    realEstateType,
  })}/${allPostType}`;
  const commonPathname =
    !router.pathname.includes(`/${routeSearchList}`) && router.pathname !== '/'
      ? router.pathname
      : pathname;
  return (
    <button
      onClick={async () => {
        await router.push({
          pathname: commonPathname,
          query: variables,
        });
      }}
      className={styles.searchButton}>
      <img alt="search" src="/svg/searchAdvanceIcon.svg" />
      <div className="text-base font-bold text-white">Tìm kiếm</div>
    </button>
  );
}
