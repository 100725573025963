import MyDropdownCustom from '@components/MyDropdownCustom';
import MultiCheckDropdownComponent from '@components/MultiCheckDropdownComponent';
import { Controller, useFormContext } from 'react-hook-form';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateCommonConfigs.json';
import React from 'react';
import CityButton from '@components/AdvanceSearch/components/Address/CityButton';
import DistrictButton from '@components/AdvanceSearch/components/Address/DistrictButton';
import AreaButton from '@components/AdvanceSearch/components/Button/AreaButton';
import PriceButton from '@components/AdvanceSearch/components/Button/PriceButton';
import Undo from '@public/svg/undoBlueIcon.svg';
import styles from '@styles/components/AdvanceSearch/RenderRows.module.scss';
import {
  defaultConfigs,
  utilitiesConfig,
} from '@components/AdvanceSearch/configs';

import { useRouter } from 'next/router';
import Collapsible from '@components/Collapsible';
import WardButton from '@components/AdvanceSearch/components/Address/WardButton';
import StreetButton from '@components/AdvanceSearch/components/Address/StreetButton';
import HasAlleyButton from '@components/AdvanceSearch/components/Button/HasAlleyButton';
import WidthButton from '@components/AdvanceSearch/components/Button/WidthButton';
import LengthButton from '@components/AdvanceSearch/components/Button/LengthButton';
import RearButton from '@components/AdvanceSearch/components/Button/RearButton';
import BedroomButton from '@components/AdvanceSearch/components/Button/BedroomButton';
import ToiletButton from '@components/AdvanceSearch/components/Button/ToiletButton';
import FloorButton from '@components/AdvanceSearch/components/Button/FloorButton';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';

export const convertPrice = (priceTo, isForSell) => {
  if (!priceTo) {
    if (isForSell === 'ForRent' || isForSell === 'ToRent') {
      return 200.5;
    } else {
      return 101;
    }
  } else {
    if (isForSell === 'ForRent' || isForSell === 'ToRent') {
      return parseInt(priceTo, 10) / Math.pow(10, 6);
    } else {
      return parseInt(priceTo, 10) / Math.pow(10, 9);
    }
  }
};

type Props = {
  expand?: boolean,
};
export default function RenderRow({ expand }: Props) {
  const router = useRouter();
  const { watch, setValue, control, reset } = useFormContext();
  const isForSell = watch('isForSell');
  const widthFrom = watch('widthFrom');
  const widthTo = watch('widthTo');
  const lengthFrom = watch('lengthFrom');
  const lengthTo = watch('lengthTo');
  const {
    forSellRealEstateTypes,
    forRentRealEstateTypes,
    projectForSaleTypes,
  } = configs;
  const isDefaultWidth = widthFrom === 0 && widthTo === 101;
  const isDefaultLength = lengthFrom === 0 && lengthTo === 501;
  const isProject = isForSell === 'Project';
  const realEstateSelections = isProject
    ? projectForSaleTypes
    : isForSell === 'ForSale' || isForSell === 'ToBuy'
    ? forSellRealEstateTypes
    : forRentRealEstateTypes;
  useDeepCompareEffect(() => {
    if (!isDefaultWidth && !isDefaultLength) {
      if (widthTo === 101 && lengthTo === 501) {
        setValue('areaFrom', 5000);
        setValue('areaTo', 20001);
      } else {
        setValue('areaFrom', widthFrom * lengthFrom);
        setValue('areaTo', widthTo * lengthTo);
      }
    }
  }, [
    lengthFrom,
    lengthTo,
    widthFrom,
    widthTo,
    isDefaultLength,
    isDefaultWidth,
  ]);

  const titleStyle = {
    fontSize: 13,
    fontWeight: 'bold',
    color: router.pathname !== '/' ? '#222' : '#fff',
  };
  return (
    <div className="flex flex-row space-x-4">
      <div
        className={`flex-1 flex flex-col ${expand ? 'space-y-5' : undefined}`}>
        <div className="w-full grid grid-cols-5 gap-4">
          <div className="space-y-2">
            <div style={titleStyle}>Loại bất động sản</div>
            <Controller
              control={control}
              name="realEstateType"
              render={({ field: { onChange, value } }) => {
                return (
                  <MyDropdownCustom
                    placeholder="Chọn giá trị"
                    key={Math.random() + value}
                    value={value}
                    setValue={(nextValue) => onChange(nextValue)}
                    configs={realEstateSelections}
                    DropdownComponent={MultiCheckDropdownComponent}
                    hideDeleteButton={value?.length === 0}
                    onDelete={() => onChange([])}
                  />
                );
              }}
            />
          </div>
          <div className="space-y-2">
            <div style={titleStyle}>Tỉnh/ Thành phố</div>
            <CityButton />
          </div>
          <div className="space-y-2">
            <div style={titleStyle}>Quận/ Huyện</div>
            <DistrictButton />
          </div>
          <div className="space-y-2">
            <div style={titleStyle}>Diện tích</div>
            <AreaButton />
          </div>
          <div className="space-y-2">
            <div style={titleStyle}>Giá</div>
            <PriceButton />
          </div>
        </div>
        <Collapsible expand={expand}>
          <div className="w-full grid grid-cols-5 gap-4">
            <div className="space-y-2">
              <div style={titleStyle}>Phường/ Xã</div>
              <WardButton />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Đường</div>
              <StreetButton />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Mặt tiền/ Trong hẻm</div>
              <HasAlleyButton />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Chiều ngang</div>
              <WidthButton />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Chiều dài</div>
              <LengthButton />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Mặt hậu</div>
              <RearButton isFilter={true} />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Số phòng ngủ</div>
              <BedroomButton />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Số nhà vệ sinh</div>
              <ToiletButton />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Số tầng</div>
              <FloorButton />
            </div>
            <div className="space-y-2">
              <div style={titleStyle}>Tiện ích</div>
              <Controller
                control={control}
                name="utilities"
                render={({ field: { onChange, value } }) => {
                  return (
                    <MyDropdownCustom
                      onDelete={() => onChange([])}
                      key={Math.random() + value}
                      value={value}
                      setValue={(nextValue) => onChange(nextValue)}
                      hideDeleteButton={value?.length === 0}
                      configs={utilitiesConfig}
                      DropdownComponent={MultiCheckDropdownComponent}
                      placeholder="Chọn giá trị"
                    />
                  );
                }}
              />
            </div>
          </div>
        </Collapsible>
      </div>
      <div
        onClick={() => {
          reset({
            ...defaultConfigs,
          });
        }}
        className={styles.undoButton}>
        <Undo width={20} height={20} />
        <div className="text-15 font-bold text-blue-main">Xóa</div>
      </div>
    </div>
  );
}
