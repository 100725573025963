import { useFormContext } from 'react-hook-form';
import React from 'react';
import FromToSelectorV1 from '@components/FromToSelectorV1';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
export default function ToiletButton() {
  const { setValue, watch } = useFormContext();
  const { toiletCountKey, realEstateType, toiletCountFrom, toiletCountTo } =
    watch();
  const { townhouse } = configs;
  const { toiletCount } = townhouse || {};
  const convertToiletCount =
    toiletCount?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 169 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <div className="space-y-2">
      <FromToSelectorV1
        renderToValue={168}
        progress={84.5}
        sliderTitle={'Số lượng nhà vệ sinh'}
        key={Math.random() + toiletCountFrom + toiletCountTo}
        deleteValue={() => {
          setValue('toiletCountKey', '');
          setValue('toiletCountFrom', 0);
          setValue('toiletCountTo', 169);
        }}
        exceptValue={
          toiletCountFrom === 0 && toiletCountTo === 3 ? '<= 3 phòng' : ''
        }
        disabled={
          realEstateType?.length === 1 && realEstateType?.[0] === 'land'
        }
        listSelections={convertToiletCount || []}
        onSelectItem={({ from, to, key }) => {
          setValue('toiletCountKey', key);
          setValue('toiletCountFrom', from);
          setValue('toiletCountTo', to);
        }}
        keyValue={toiletCountKey}
        from={toiletCountFrom}
        to={toiletCountTo}
        unit={'phòng'}
        min={0}
        max={168}
        step={1}
        additionalItems={[{ key: '', from: 0, to: 169, value: 'Tất cả' }]}
      />
    </div>
  );
}
