import { useFormContext } from 'react-hook-form';
import React from 'react';
import FromToSelectorV1 from '@components/FromToSelectorV1';
import configs from '@utils/configs/RealEstateConfiguarations/RealEstateFilterConfigs.json';
export default function LengthButton() {
  const { setValue, watch } = useFormContext();
  const { lengthKey, lengthFrom, lengthTo } = watch();
  const { townhouse } = configs;
  const { length } = townhouse || {};
  const convertLength =
    length?.map((e) => {
      return {
        from: e.from,
        to: !e?.to ? 501 : e.to,
        value: e.value,
        key: e.key,
        unit: e.unit,
      };
    }) || [];
  return (
    <div className="space-y-2">
      <FromToSelectorV1
        stepRenderLine={50}
        renderToValue={500}
        progress={250.5}
        sliderTitle={'Chiều dài (m)'}
        key={Math.random() + lengthFrom + lengthTo}
        deleteValue={() => {
          setValue('lengthKey', '');
          setValue('lengthFrom', 0);
          setValue('lengthTo', 501);
          setValue('areaKey', '');
          setValue('areaFrom', 0);
          setValue('areaTo', 20001);
        }}
        exceptValue={
          (lengthFrom === 0 || !lengthFrom) && lengthTo === 10
            ? '< 10 m'
            : lengthFrom === 500 && lengthTo === 501
            ? '> 500 m'
            : ''
        }
        theValueIsShow={500}
        listSelections={convertLength || []}
        onSelectItem={({ from, to, key }) => {
          setValue('lengthKey', key);
          setValue('lengthFrom', from);
          setValue('lengthTo', to);
          setValue('areaKey', '');
          setValue('areaFrom', 0);
          setValue('areaTo', 20001);
        }}
        keyValue={lengthKey}
        from={lengthFrom}
        to={lengthTo}
        unit={'m'}
        min={0}
        max={500}
        step={1}
        additionalItems={[{ key: '', from: 0, to: 501, value: 'Tất cả' }]}
      />
    </div>
  );
}
