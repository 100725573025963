import SelectComponent from '@components/SelectComponent';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_LIST_STREET_OF_DISTRICT,
  GET_LIST_STREETS_OF_ONE_WARD,
} from '@schemas/queries/map';
import { convertDataSelections } from '@components/AddressDropdown/CityDropdown';
import DropdownForSearchList from '@components/AdvanceSearchForSearchList/components/DropdownForSearchList';
import { HCMId } from '@utils/configs/Common/CommonConfigs';

export default function StreetButton() {
  const { control, watch } = useFormContext();
  const { district, ward, province } = watch();
  let queryStreet,
    queryVariables = '';
  if (province !== HCMId) {
    (queryStreet = GET_LIST_STREET_OF_DISTRICT),
      (queryVariables = { districtId: district, limit: 1500 });
  } else {
    (queryStreet = ward
      ? GET_LIST_STREETS_OF_ONE_WARD
      : GET_LIST_STREET_OF_DISTRICT),
      (queryVariables = ward
        ? {
            wardId: ward,
            limit: 1500,
          }
        : { districtId: district, limit: 1500 });
  }
  const { data, loading } = useQuery(queryStreet, {
    variables: {
      ...queryVariables,
    },
    skip: !district && !ward,
  });

  const resultPath =
    ward && province === HCMId
      ? 'getListStreetsOfOneWard'
      : 'getListStreetsOfDistrict';

  const rawData = [
    { id: '', name: 'Tất cả' },
    ...(data?.[resultPath]?.streets || []),
  ];
  const dataSelections = convertDataSelections(rawData);
  const [disabledWhenDelete, setDisabledWhenDelete] = useState(false);
  return (
    <Controller
      control={control}
      name="street"
      render={({ field: { onChange, value } }) => {
        return (
          <DropdownForSearchList
            titleMobile="Đường"
            key={Math.random() + value}
            onDelete={() => {
              onChange('');
              setDisabledWhenDelete(true);
              setTimeout(() => {
                setDisabledWhenDelete(false);
              }, 100);
            }}
            value={value}
            setValue={(nextValue) => {
              onChange(nextValue);
            }}
            disabled={disabledWhenDelete}
            hideDeleteButton={value === ''}
            configs={dataSelections}
            isShowSearchBox
            DropdownComponent={SelectComponent}
            contextProps={{ hasItemRadio: true, loading }}
            customValue={() => {
              return (
                <div
                  className={`flex-1 px-2 text-left text-13 truncate text-blue-main `}>
                  {dataSelections?.find((e) => e.key === value)?.value ||
                    'Chọn giá trị'}
                </div>
              );
            }}
          />
        );
      }}
    />
  );
}
